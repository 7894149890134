<template>
  <div class="password-reset">
    <div
      class="password-reset__content"
      data-test="content"
    >
      <h1
        data-test="title"
        class="password-reset__content__title tw-text-3xl tw-font-light tw-text-center tw-mb-4"
        v-text="$t('auth.titles.password_reset')"
      />
    </div>

    <ValidationObserver
      ref="observer"
    >
      <form
        :disabled="$wait.is('updating password')"
        data-test="form"
        @submit.prevent="submitted"
      >
        <ValidationProvider
          ref="password-provider"
          vid="password"
          :name="$t('app.fields.password')"
          rules="required|min:12"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              v-model="formData.password"
              id="password"
              name="password"
              type="password"
              :error="invalid && validated"
              :hint="errors[0]"
              :label="$t('auth.labels.password_new') | capitalize"
              :disabled="$wait.is('signing in')"
              class="tw-w-full tw-mb-4"
              autocomplete="new-password"
              data-test="password"
              required
            />
          </template>
        </ValidationProvider>

        <ValidationProvider
          ref="password-confirmation-provider"
          :name="$t('app.fields.password')"
          rules="required|min:12|confirmed:password"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              v-model="formData.passwordConfirmation"
              id="password-confirmation"
              name="password-confirmation"
              type="password"
              :error="invalid && validated"
              :hint="errors[0]"
              :label="$t('app.labels.password_confirmation') | capitalize"
              :disabled="$wait.is('signing in')"
              class="tw-w-full tw-mb-4"
              autocomplete="new-password"
              data-test="password-confirmation"
              required
            />
          </template>
        </ValidationProvider>

        <p
          class="password__form__password-explanation tw-mb-6 tw-italic"
          data-test="password-constraint"
        >
          {{ $t('app.password.paragraphs.password_constraint') }}
        </p>

        <p
          v-if="$err.has('invalid_token')"
          v-text="$t('auth.paragraphs.error.invalid_token')"
          class="tw-bg-red-500 tw-px-4 tw-py-2 tw-rounded tw-text-white"
          data-test="error"
        />

        <ui-button
          :disabled="$wait.is('updating password')"
          :loading="$wait.is('updating password')"
          variant="primary"
          size="md"
          class="tw-w-full"
          type="submit"
          data-test="submit"
        >
          {{ $t('validate') | capitalize }}
        </ui-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import store from '@/store'

  import { Auth } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import CtkInputText from '@/components/CtkInputs/CtkInputText'
  import UiButton from '@/components/UI/Button'

  /**
   * @module view - PasswordReset
   */
  export default {
    name: 'PasswordReset',
    components: {
      UiButton,
      CtkInputText
    },
    beforeRouteEnter (to, from, next) {
      if (!to.query.token) {
        return next({
          name: 'PasswordForgot'
        })
      }

      if (to.query.token) {
        store.dispatch('auth/setToken', to.query.token)
      }

      next()
    },
    mounted () {
      this.$err.hide('invalid_token')
    },
    metaInfo () {
      return {
        title: this.$t('auth.meta.password_reset_title')
      }
    },
    data () {
      return {
        formData: {
          password: null,
          passwordConfirmation: null
        }
      }
    },
    methods: {
      submitted () {
        this.$err.hide('invalid_token')
        this.$refs.observer.validate()
          .then(valid => {
            if (!valid || this.$wait.is('updating password')) return false

            const { password } = this.formData

            this.$wait.start('updating password')
            Auth.renewPassword({}, {
              password
            })
              .then(() => {
                showToaster(
                  this,
                  this.$t('auth.paragraphs.password_reset.updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )

                this.$router.push({
                  name: 'SignIn'
                })
                  .catch(() => {})
              })
              .catch(err => {
                if (!err.response) return

                const { data, status } = err.response

                if (status === 401) {
                  this.$err.show('invalid_token')
                } else {
                  const dataErrorViolations = data && data.error && data.error.violations
                  const hasViolations = !!dataErrorViolations

                  const dataErrorTitle = data && data.error && data.error.title
                    ? data.error.title
                    : this.$t('an_error_has_occurred')

                  const errorMessage = !hasViolations ? dataErrorTitle : this.$t('an_error_has_occurred')

                  if (hasViolations) {
                    dataErrorViolations.forEach(violation => {
                      const provider = this.$refs[`${violation.property_path}-provider`]
                      if (provider) {
                        provider.setErrors([violation.message])
                      }
                    })

                    return false
                  }

                  showToaster(this, errorMessage, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => this.$wait.end('updating password'))
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
.password-reset__content {
  margin-bottom: 2.5rem;
}
.password-reset__content__explanation {
  color: $secondary-text;
}
@media only screen and (max-width: $breakpoint-mobile-m) {
  .password-reset__content {
    margin-top: 16px;
    margin-bottom: 64px;
  }
}
</style>
