<template>
  <div
    class="auth tw-flex tw-flex-1 tw-flex-col md:tw-flex-row"
  >
    <div
      class="auth__content md:tw-overflow-hidden tw-flex-1 tw-flex tw-flex-col"
      data-test="content"
    >
      <auth-header
        data-test="header"
      />
      <div class="auth__content__inner tw-w-full tw-py-4 tw-px-3">
        <router-view />
      </div>

      <template
        v-if="$route.name === 'InviteConfirmation'"
      >
        <!--
          This is a quick workaround to include the full width illustration
          in the invite confirmation view. That way, we don't have to rework
          the auth parent view.
        -->
        <div
          class="tw-hidden tw-mb-12 tw-w-full md:tw-flex tw-justify-center"
          data-test="illustration"
        >
          <img
            src="@/assets/img/illustrations/shipment_confirmation.svg"
            alt=""
            height="220"
            class="tw-select-none"
            data-test="img"
          >
        </div>
      </template>
    </div>
    <auth-side-explanations />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import store from '@/store'

  import AuthHeader from './components/AuthHeader/index.vue'
  import AuthSideExplanations from './components/AuthSideExplanations/index.vue'

  /**
   * @module view - auth
   */
  export default defineComponent({
    name: 'Auth',
    components: {
      AuthHeader,
      AuthSideExplanations
    },
    metaInfo () {
      return {
        title: this.$t('auth.meta.title')
      }
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)
      next()
    }
  })
</script>

<style lang="scss" scoped>

  .auth {
    background: white;

    &__content {
      flex: 1;
    }

    .auth-side-explanations {
      flex: 0 0 40%;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
      &__content__inner {
        width: 370px;
        margin: auto;
      }

      &__content {
        flex: 0 0 60%;
      }
    }
  }

</style>
