var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[_c('div',{staticClass:"sign-in__content tw-py-4 md:tw-pb-10"},[_c('h1',{staticClass:"sign-in__content__title tw-text-3xl tw-font-light tw-text-center tw-mb-4",domProps:{"textContent":_vm._s(_vm.$t('auth.titles.sign_in'))}}),_c('h2',{staticClass:"sign-in__content__subtitle tw-text-gray-600 tw-text-xl tw-font-light tw-text-center",domProps:{"textContent":_vm._s(_vm.$t('auth.titles.sign_in_subtitle'))}})]),_c('ValidationObserver',{ref:"observer"},[_c('form',{attrs:{"disabled":_vm.$wait.is('signing in')},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"email-provider",attrs:{"name":_vm.$t('app.fields.email'),"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full tw-mb-4",attrs:{"id":"email","name":"email","type":"email","error":invalid && validated,"hint":errors[0],"label":_vm._f("capitalize")(_vm.$t('app.labels.email')),"disabled":_vm.$wait.is('signing in'),"autocomplete":"email","required":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('app.fields.password'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full tw-mb-4",attrs:{"id":"password","name":"password","type":"password","error":invalid && validated,"hint":errors[0],"label":_vm._f("capitalize")(_vm.$t('app.labels.password')),"disabled":_vm.$wait.is('signing in'),"autocomplete":"current-password","required":""},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}])}),(_vm.$err.has('invalid_login'))?_c('div',{staticClass:"tw-bg-red-500 tw-px-4 tw-py-2 tw-rounded tw-text-white tw-mb-3"},[_c('div',{directives:[{name:"markdown",rawName:"v-markdown",value:(_vm.$t('auth.paragraphs.error.invalid_login_link_ccp',{ ccp_link: _vm.ccpLink })),expression:"$t('auth.paragraphs.error.invalid_login_link_ccp',{ ccp_link: ccpLink })"}],staticClass:"signin-error-message"})]):_vm._e(),(_vm.$err.has('user_password_expired'))?_c('p',{staticClass:"tw-bg-red-500 tw-px-4 tw-py-2 tw-text-white",domProps:{"textContent":_vm._s(_vm.$t('auth.paragraphs.error.user_password_expired'))}}):_vm._e(),(_vm.$err.has('user_disabled'))?_c('p',{staticClass:"tw-bg-red-500 tw-px-4 tw-py-2 tw-rounded tw-text-white",domProps:{"textContent":_vm._s(_vm.$t('auth.paragraphs.error.user_disabled'))}}):_vm._e(),_c('UiButton',{staticClass:"tw-w-full",attrs:{"disabled":_vm.$wait.is('signing in'),"loading":_vm.$wait.is('signing in'),"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('auth.buttons.sign_in'))+" ")]),_c('UiButton',{staticClass:"tw-mt-4 tw-w-full",attrs:{"to":{
          name: 'PasswordForgot'
        },"variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('auth.buttons.password_forgot_link'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }