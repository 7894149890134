var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"password-reset"},[_c('div',{staticClass:"password-reset__content"},[_c('h1',{staticClass:"password-reset__content__title tw-text-3xl tw-font-light tw-text-center tw-mb-4",domProps:{"textContent":_vm._s(_vm.$t('auth.titles.password_reset'))}})]),_c('ValidationObserver',{ref:"observer"},[_c('form',{attrs:{"disabled":_vm.$wait.is('updating password')},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"password-provider",attrs:{"vid":"password","name":_vm.$t('app.fields.password'),"rules":"required|min:12","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full tw-mb-4",attrs:{"id":"password","name":"password","type":"password","error":invalid && validated,"hint":errors[0],"label":_vm._f("capitalize")(_vm.$t('auth.labels.password_new')),"disabled":_vm.$wait.is('signing in'),"autocomplete":"new-password","required":""},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}])}),_c('ValidationProvider',{ref:"password-confirmation-provider",attrs:{"name":_vm.$t('app.fields.password'),"rules":"required|min:12|confirmed:password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full tw-mb-4",attrs:{"id":"password-confirmation","name":"password-confirmation","type":"password","error":invalid && validated,"hint":errors[0],"label":_vm._f("capitalize")(_vm.$t('app.labels.password_confirmation')),"disabled":_vm.$wait.is('signing in'),"autocomplete":"new-password","required":""},model:{value:(_vm.formData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirmation", $$v)},expression:"formData.passwordConfirmation"}})]}}])}),_c('p',{staticClass:"password__form__password-explanation tw-mb-6 tw-italic"},[_vm._v(" "+_vm._s(_vm.$t('app.password.paragraphs.password_constraint'))+" ")]),(_vm.$err.has('invalid_token'))?_c('p',{staticClass:"tw-bg-red-500 tw-px-4 tw-py-2 tw-rounded tw-text-white",domProps:{"textContent":_vm._s(_vm.$t('auth.paragraphs.error.invalid_token'))}}):_vm._e(),_c('ui-button',{staticClass:"tw-w-full",attrs:{"disabled":_vm.$wait.is('updating password'),"loading":_vm.$wait.is('updating password'),"variant":"primary","size":"md","type":"submit"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('validate')))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }