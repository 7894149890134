<template>
  <div
    class="invite-confirmation tw-flex tw-flex-col tw-items-center tw-w-full tw-py-16"
  >
    <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
      <picture class="tw-mb-8">
        <img
          src="@/assets/img/icons/approved.svg"
          width="70"
          height="70"
          alt=""
          data-test="icon"
        >
      </picture>
      <h1
        v-text="$t('auth.titles.invite.welcome')"
        class="tw-text-2xl tw-mb-4"
        data-test="title"
      />
      <p
        class="tw-text-center tw-mb-6"
        v-text="$t('auth.paragraphs.invite.welcome')"
        data-test="content"
      />

      <ui-button
        class="tw-px-4"
        variant="primary"
        data-test="button"
        @click="signIn"
      >
        {{ $t('app.buttons.view_member_area') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import useWait from '@/composables/useWait'
  import useRouter from '@/composables/useRouter'
  import store from '@/store'
  import { showToaster } from '@/services/Toaster'
  import { i18n } from '@/locales'

  export default defineComponent({
    name: 'InviteConfirmation',
    setup (props, { root }) {
      const wait = useWait()
      const router = useRouter()

      function signIn () {
        const { email, password } = store.getters['auth/register/getAccount']

        wait.start('signing in')
        store.dispatch('auth/signIn', {
          email,
          password
        })
          .then(async () => {
            router.value.push({
              name: 'Dashboard'
            })
              .catch(() => {})
              .finally(() => {
                store.dispatch('setAppReady', true)
                wait.end('signing in')

                if (store.getters['auth/isFirstConnection']) {
                  setTimeout(() => {
                    store.dispatch('ui/setWelcomeVisible', true)
                  }, 2000, true)
                }
              })
          })
          .catch(err => {
            if (!err.response) return

            let message = i18n.t('an_error_has_occurred')
            if (err && err.response && err.response.status === 401) {
              message = i18n.t('auth.paragraphs.error.invalid_login')
            }

            showToaster(root, message, {
              type: 'error',
              position: 'bottom-left'
            })
          })
          .finally(() => {
            wait.end('signing in')
          })
      }

      return {
        signIn
      }
    }
  })
</script>
